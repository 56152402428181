import Dexie from 'dexie';

export const db = new Dexie('myjwdb');

export const TABLES = {
    SHIFT_TEMPLATES: 'shiftTemplates',
    SHIFT_PLACES: 'shiftPlaces',
    SHIFTS: 'shifts',
}

const shiftTemplates =
    'id,' +
    'name,' +
    'description,' +
    'repetition,' +
    'placeId,' +
    'dayOfWeek,' +
    'timeFrom,' +
    'timeTo,' +
    'maxPeople,' +
    'requireApproval'

const shiftPlaces =
    'id,' +
    'name,' +
    'description,' +
    'locationLink1,' +
    'locationLink2'

const shifts =
    "id," +
    "description," +
    "placeId," +
    "date," +
    "timeFrom," +
    "timeTo," +
    "maxPeople," +
    "requireApproval," +
    "users," +
    "likes"

db.version(1).stores({
    shiftTemplates,
    shiftPlaces,
    shifts,
});

export function initTable(table, listOfItems) {
    db[table].where("id").noneOf(listOfItems.map(it => it.id)).delete();
    db[table].bulkPut(structuredClone(listOfItems))
}

export function deleteOnLogout() {
    db.shiftTemplates.clear()
    db.shiftPlaces.clear()
    db.shifts.clear()
}

// https://dexie.org/docs/Tutorial/Vue